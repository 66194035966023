@import url('https://fonts.googleapis.com/css?family=DM+Sans:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Rubik:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Nunito:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Roboto:regular,bold,italic&subset=latin,latin-ext');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'DM Sans' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.lg-screen {
  display: block;
}
.sm-screen {
  display: none;
}
.form-control {
  background-color: #F7F7F7 !important;
}
.project4 {
  background-image: linear-gradient(to bottom, rgba(25, 68, 95, 0.52), rgba(98, 98, 98, 0.73)), url("./images/bg6.png");
  background-size: cover;
  background-position: center;
  margin-top: 80px !important;
  padding-top: 75px;
  padding-bottom: 75px;
  cursor: pointer;
}
.events .title {
  font-family: 'Rubik'; 
}
.events .event1 {
  background-color: #FEF0DF;
  cursor: pointer;
}
.events .event1 h4 {
  width: 90%;
}
.events .event2 {
  background-color: #FEF0DF;
  cursor: pointer;
}
.events .smaller {
  font-size: smaller;
  font-weight: 600;
}
.events .rounded-circle {
  width: 35px;
  height: 35px;
}
.breadcrumb .breadcrumb-item span {
  color: rgba(0, 0, 0, 0.4);
}
.breadcrumb .active {
  color: black !important;
}
.product {
  margin-top: 65px;
  margin-bottom: 150px;
}
.product .details .benefits ul {
  padding-left: 20px;  
  list-style-image: url('images/ellipse1.svg');
}
.product .details .benefits ul li {
  padding-bottom: 8px;
}
.product .section1 {
  background-image: url("./images/bg_lube.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 80vh;
}
.product .section2 {
  padding-top: 30px;
}
.product .details {
  padding-top: 30px;
}
.product .details h4 {
  font-weight: 600;
}
.product .sample {
  float: right;
}
.product .download {
  cursor: pointer;
}
.product .btn-warning {
  background-color: #F8A135;
}



/* Header component */
.Header {
  height: 8vh;
  padding-right: 50px;
  padding-left: 50px;
}
.Header .navbar-nav .nav-item {
  z-index: 10;
  color: pink !important;
}
.Header .navbar-nav .active .nav-link {
  color: #152C41 !important;
  font-weight: 600;
}
@media only screen and (max-width: 768px) {
  .Header {
    padding-right: 0px;
    padding-left: 0px;
  }
}



/* Home */
.Home {
  margin-top: 65px;
}
.Home .btn-warning {
  background-color: #F8A135 !important;
}
.Home h1 {
  color: #1D2130;
  font-family: 'Rubik';
}
.Home .main-text {
  color: #525560;
}
.Home .section1 {
  height: 93vh;
}
.Home .section1 .carousel-item {
  height: 93vh;
}
.Home .section1 .carousel-item .banner{
  height: 100vh;
  width: 100vw;
}
.Home .section1 .carousel-caption {
  left: 0px;
  bottom: 0px;
  padding-left: 50px;
  background: linear-gradient(to top ,#090806 10%, #03030200 90%) ;
  width: 100vw;
  /* background-color: red; */
  /* height: 300px; */
}
@media only screen and (max-width: 768px) {
  .Home .section1 .carousel-caption {
    padding-left: 15px;
  }
  .Home .section1 {
    height: 70vh;
  }
  .Home .section1 .carousel-item {
    height: 70vh;
  }
  .Home .section1 .carousel-item .banner {
    height: 70vh;
  }
}

.Home .section1 .carousel-caption h1 {
  text-shadow: 0 0 40px black;
}

.Home .section1 .carouselCaptionImage {
  width: 40%;
  bottom: 0px;
}
.Home .section1 .carouselCaptionImage1 {
  width: 45%;
}
.Home .section1 .oilSample1 {
  width: 53vw;
  position: relative;
  left: 25px;
  top: 130px;
}
.Home .section1 .bg2 {
  width: 100vw;
  left: 0px;
  top: 60px;
  z-index: 1;
}

.Home .section2 {
  padding-top: 80px;
  padding-bottom: 80px;
}
.Home .section2 h1 {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.Home .section2 .oilSample2 {
  width: 40vw !important;
  float: right;
  margin-top: -70px;
  /* position: relative;
  left: 25px; */
}
@media only screen and (max-width: 768px) {
  .Home .section2 {
    padding-top: 30px;
  }
  .Home .section2 .oilSample2 {
    width: 90vw !important;
    float: none;
    transition: 1s all ease;
    margin-top: 20px;
    /* position: relative;
    left: 25px; */
  }
}

.Home .section2 .row {
  padding-left: 100px;  
}
.Home .section2 .header {
  color: #AFAFAF;
}
.Home .section2 .main-text {
  font-family: 'Nunito';
}
.Home .section2 .btn-warning {
  font-family: 'Roboto';
}
.Home .section2 .footer span {
  width: 200px;
}
@media only screen and (max-width: 768px) {
  .Home .section2 .btn-warning {
    width: 90vw;
  }  
  .Home .section2 .content{
    padding-left: 15px;
    padding-right: 15px;
  }
}  

.Home .section3 {
  background-color: #F8F8F8;
}
.Home .section3 .oilSample4 {
  width: 45vw !important;
}
.Home .section3 .content {
  padding-left: 100px; 
}
.Home .section3 .verticalLine1 {
  top: 20px;
  left: -1px;
}
@media only screen and (max-width: 768px) {
  .Home .section3 .content {
    padding-left: 15px; 
    padding-right: 15px;
  }
  .Home .section3 .products {
    width: 100% !important;
  }
  .Home .section3 .btn-warning {
    width: 90vw;
  }  
}

.Home .section4 .content h1{
  padding-left: 100px;
}
@media only screen and (max-width: 768px) { 
  .Home .section4 .content h1{
    padding-left: 15px;
    padding-right: 15px;
    width: 100% !important;
  } 
  .Home .section4 .content p{
    padding-left: 15px;
    padding-right: 15px;
    width: 100% !important;
  }
}

.Home .section4 .projects .project1 {
  background-image: linear-gradient(to bottom, rgba(36, 36, 37, 0.2), rgba(9, 9, 9, 0.2)), url("./images/person1.png");
  background-size: cover;
  height: 50vh;
}
.Home .section4 .projects .project2 {
  background-image: linear-gradient(to bottom, rgba(36, 36, 37, 0.2), rgba(9, 9, 9, 0.2)), url("./images/person2.png");
  background-size: cover;
  background-position: center;
  height: 50vh;
}.Home .section4 .projects .project2 img{ 
  width: 200px;
}
.Home .section4 .projects .project3 {
  background-image: linear-gradient(to bottom, rgba(36, 36, 37, 0.2), rgba(9, 9, 9, 0.2)), url("./images/person3.png");
  background-size: cover;
  background-position: center;
  height: 50vh;
  /* cursor: pointer; */
}
.Home .section4 .projects .project1:hover {
  background-image: linear-gradient(to bottom, rgba(36, 36, 37, 0.1), rgba(9, 9, 9, 0.1)), url("./images/person1.png");
}
.Home .section4 .projects .project2:hover {
  background-image: linear-gradient(to bottom, rgba(36, 36, 37, 0.1), rgba(9, 9, 9, 0.1)), url("./images/person2.png");
}
.Home .section4 .projects .project3:hover {
  background-image: linear-gradient(to bottom, rgba(36, 36, 37, 0.1), rgba(9, 9, 9, 0.1)), url("./images/person3.png");
}
.Home .section4 .card {
  cursor: pointer;
}
.Home .section4 .form {
  margin-top: 150px;
}


/* Footer */
.Footer {
  color: white;  
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: #152C41;
}
.Footer span {
  color: rgba(224, 225, 225, 0.7);
}
.Footer ul {
  padding-left: 0;
  list-style-type: none;
}
.Footer ul li{
  color: rgba(224, 225, 225, 0.7);
  cursor: pointer;
  padding-bottom: 10px;
  transition: all .3s;
}
.Footer ul li:hover {
  color: rgba(224, 225, 225);
}
.Footer .btn-warning {
  background-color: #F8A135 !important;
}

/* Lubricants */
.Lubricants {
  margin-top: 65px;
}
.Lubricants .section1 {
  background-image: url("./images/bg_lube.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 80vh;
}
.Lubricants .section2 {
  padding-top: 30px;
}
.Lubricants .side-links a {
 color: black;
 font-weight: 600;
 text-decoration: none;
 cursor: pointer;
}
.Lubricants .section2 .side-links .active {
  color: #F8A135 !important;
}
.Lubricants .section2 .container {
  width: 70vw;
}
.Lubricants .card {
  margin-bottom: 40px;
}
.Lubricants .card .card-img-top {
  width: 180px;
  height: 30vh;
}
.Lubricants .card .hdeo1 {
  height: 20vh;
}
.Lubricants .card .hdeo2 {
  height: 35vh;
}
@media only screen and (max-width: 768px) {
  .Lubricants .card .card-img-top {
    width: 40vw;
    height: 15vh;
  }  
  .Lubricants .card .hdeo1 {
    height: 12vh;
  }  
  .product .sample {
    float: right;
    width: 80vw;
  }
}

.Lubricants .card .card-title {
  font-weight: 600;
}
.Lubricants .btn-warning {
  background-color: #F8A135;
}


.reveal {
  position: relative;
  transform: translateY(100px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}
.animate2 {
  position: relative;
  transform: translateX(100px);
  opacity: 0;
  transition: 1s all ease;
}
.animate2.active {
  transform: translateX(0);
  opacity: 1;
}
.animate3 {
  transform: translateX(-100px);
  opacity: 0;
  transition: 1s all ease;
}
.animate3.active {
  transform: translateX(0);
  opacity: 1;
}

@media only screen and (max-width: 768px) {
    .lg-screen {
      display: none;
    }
    .sm-screen {
      display: block;
  }
}

/* news */ 
.news {
  padding: 50px;
  padding: 50px;
}
@media (max-width: 768px) {
  .news {
    padding-left: 15px;
    padding-right: 15px;
 }
}
@media (max-width: 768px) {
    .line {
      width: 40%;
      max-width: 100%; /* Ensure it doesn't exceed the viewport width */
  }
}